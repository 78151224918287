import { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Form, Button, Badge } from "react-bootstrap";
import classNames from "classnames";
import SimpleBar from "simplebar-react";

import Layout from "../../../layout/default";
import {
  ChatList,
  CustomDropdownMenu,
  CustomDropdownToggle,
  Icon,
  Image,
  MediaGroup,
  Media,
  MediaText,
  Message,
  ChatMedia,
} from "../../../components";
import { useHotelContext } from "../../../App";
import { TbWorldSearch } from "react-icons/tb";
import { SiWhatsapp } from "react-icons/si";
import { Break } from "../../../utilities";
import ChatMessageScreen from "../../../components/Apps/chat/ChatMessageScreen";
import {
  getConversationsApi,
  getMessagesApi,
  getConversationByIdApi,
  markReadApi,
  getUnreadConversationsApi,
  fetchMyHotelsApi,
} from "../../../services/apiServices";
import storageService from "../../../services/storageServices";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import io from "socket.io-client";
import dayjs from "dayjs";
import Loader from "../../../components/Loader/Loader";
import notificationSound from "../../../../src/assets/notification.wav";
import { trackEvent } from "../../../analytics";
const hotelIdString = storageService.get("hotel_ids");

const userId = storageService.get("userId");

const socket = io("https://chatsocket.hotelzify.com", {
  query: {
    // hotelId: storageService.get("hotelId"),
    hotelIds: hotelIdString,
    userId: userId,
    // hotelIds: JSON.stringify(hotelIds),
  },
  extraHeaders: {
    Authorization: `Bearer ${storageService.get("token")}`,
  },
});

function ChatsPage() {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [active, setActive] = useState("");
  // const [selectedChat, setSelectedChat] = useState(null);
  // const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // const [isAI, setIsAI] = useState(true);
  const location = useLocation();
  // const [selectedHotel, setSelectedHotel] = useState(null);
  const [nextLoading, setNextLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const {
    conversations,
    setConversations,
    selectedHotel,
    setSelectedHotel,
    selectedChat,
    setSelectedChat,
    isAI,
    setIsAI,
  } = useHotelContext();
  const [hotelIds, setHotelIds] = useState([]);
  const observer = useRef();
  const [isScrollLoading, setIsScrollLoading] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState("all");
  const [randomLastName, setRandomLastName] = useState(null);

  const lastChatRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && isFetchLoading) {
          trackEvent("scrol-for-more-chats");
          console.log("the filter", filter);
          setCurrentPage((prev) => {
            console.log(prev);
            fetchConversations({ page: prev + 1 });
            return prev + 1;
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, isScrollLoading, filter]
  );

  useEffect(() => {
    const token = storageService.get("token");
    const hotelId = storageService.get("hotelId");
    if (!token) {
      navigate("/auths/auth-login");
      return;
    }

    // if (hotelId) {
    //   fetchConversations(hotelId);
    // }
  }, [navigate, selectedHotel]);

  useEffect(() => {
    // console.log("Current page changed:", currentPage);
    if (currentPage === 1) return;
    // fetchConversations();
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    windowResize();
    // fetchConversations();
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  useEffect(() => {
    const fetchHotelIds = async () => {
      try {
        const response = await fetchMyHotelsApi();
        const ids = response.data.map((hotel) => hotel.id);
        setHotelIds(ids);
        const hotelIdString = ids.join(",");
        console.log("Fetched hotel IDs:", ids);
        console.log("Hotel ID String:", hotelIdString);
      } catch (error) {
        console.error("Error fetching hotel IDs:", error);
      }
    };

    fetchHotelIds();
  }, []);

  const pageSize = 100;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [nextDisabled, setNextDisabled] = useState(false);

  const fetchConversations = async ({
    isReset = false,
    newFilter = filter,
    page = 1,
  } = {}) => {
    console.log("filter ", newFilter);
    console.log("hotelIdSting", hotelIdString);
    if (!hasMore && !isReset) return;
    try {
      setNextLoading(true);
      setIsFetchLoading(true);
      const requestApi =
        newFilter === "unread"
          ? getUnreadConversationsApi
          : getConversationsApi;
      const response = await requestApi(
        hotelIdString,
        isReset ? 1 : page,
        pageSize
      );

      setCurrentPage(page);
      if (isReset) {
        setSelectedChat("");
        setCurrentPage(1);
      }

      if (response.status === 200 && Array.isArray(response.data)) {
        if (isReset) {
          setConversations(response.data);
        } else {
          setConversations((conversations) => [
            ...conversations,
            ...response.data,
          ]);
        }
        if (response.data.length < 12) {
          setHasMore(false);
        }
        setHasMore(response.data.length >= 12);

        // Calculate the total number of pages
        const totalItems = response.data.length;
        setTotalPages(Math.ceil(totalItems / pageSize));
      } else {
        console.error("Unexpected API response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching the conversations:", error);
    } finally {
      setLoading(false);
      setNextLoading(false);
      setIsFetchLoading(false);
    }
  };

  useEffect(() => {
    fetchConversationDetails();
  }, []);

  const fetchConversationDetails = async () => {
    try {
      setLoading(true);
      const token = storageService.get("token");
      if (!token) {
        navigate(
          `/auths/auth-login?redirect=${encodeURIComponent(
            location.pathname + location.search
          )}`
        );
        return;
      }

      const queryParams = new URLSearchParams(window.location.search);
      const conversationId = queryParams.get("conversationId");

      if (conversationId) {
        const conversationByIdResponse = await getConversationByIdApi(
          conversationId
        );

        if (
          conversationByIdResponse.status === 200 &&
          conversationByIdResponse.data
        ) {
          const conversationById = conversationByIdResponse.data;
          setSelectedChat(conversationById);
          conversationById.isRead = true;

          const messagesResponse = await getMessagesApi(conversationId);

          if (
            messagesResponse.status === 200 &&
            Array.isArray(messagesResponse.data)
          ) {
            setMessages(messagesResponse.data);
          } else {
            console.error(
              "Unexpected messages API response structure:",
              messagesResponse
            );
          }

          // Fetch all conversations and update the state
          const conversationsResponse = await getConversationsApi(
            hotelIdString
          );
          if (
            conversationsResponse.status === 200 &&
            Array.isArray(conversationsResponse.data)
          ) {
            const updatedConversations = [
              conversationById,
              ...conversationsResponse.data.filter(
                (conv) => conv._id !== conversationById._id
              ),
            ];
            setConversations(updatedConversations);
          }
        } else {
          toast.error("No conversation found");
          fetchConversations();
          console.error(
            "Unexpected API response structure:",
            conversationByIdResponse
          );
        }
      } else {
        // If no conversationId, fetch all conversations as before
        const conversationsResponse = await getConversationsApi(hotelIdString);
        if (
          conversationsResponse.status === 200 &&
          Array.isArray(conversationsResponse.data)
        ) {
          setConversations(conversationsResponse.data);
          setHasMore(conversationsResponse.data.length >= 12);
        } else {
          console.error(
            "Unexpected API response structure:",
            conversationsResponse
          );
        }
      }
    } catch (error) {
      console.error("Error fetching conversation details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedChat) {
      // console.log("Selected chat updated in useEffect:", selectedChat);
    }
  }, [selectedChat]);

  const chatProfileClasses = classNames({
    "nk-chat-profile": true,
    "show-profile": sidebar,
    "toggle-collapsed": window.innerWidth < Break.xxl,
  });

  // toggle chat parent class
  const parentClasses = classNames({
    "nk-chat": true,
    "profile-shown": sidebar && window.innerWidth > Break.xxl,
  });

  // toggle chat body class
  const chatBodyClasses = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
  });

  const windowResize = () => {
    if (window.innerWidth > Break.xxl) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  };

  // chat profile `handle`
  const handleChatToggle = () => {
    setSidebar(!sidebar);
  };

  const markChatAsRead = async (conversationId) => {
    try {
      // setLoading(true)
      await markReadApi({ conversationId });
    } catch (error) {
      console.error("Error marking chat as read:", error);
    } finally {
      setLoading(false);
    }
  };

  // handle chat list toggle
  const handleChatListToggle = async (chat, index) => {
    trackEvent("selected-chat");

    // console.log("ai", chat);
    setSelectedChat(chat);
    setRandomLastName(Math.floor(1000 + Math.random() * 9000))
    chat.isRead = true;
    markChatAsRead(chat?._id);
    setActive(chat?._id);
    setIsAI(chat?.isBotActive);
    // setHotelName(chat?.)
    if (window.innerWidth < Break.md) {
      setMobileView(true);
    }
    const conversationId = chat._id;
    // console.log("Fetching messages for conversation ID:", conversationId);

    try {
      setLoadingMessages(true);
      const response = await getMessagesApi(conversationId);
      // console.log("API response:", response);

      if (response.status === 200 && Array.isArray(response.data)) {
        setMessages(response.data);
      } else {
        console.error(
          "Unexpected API response structure for messages:",
          response
        );
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      trackEvent("error-fetching-messages", error);
      setMessages([]);
    } finally {
      setLoadingMessages(false);
    }
  };

  // hide showing chat
  const handleChatListHide = () => {
    setMobileView(false);
    setSelectedChat(null);
    setMessages([]);
  };

  useEffect(() => {
    window.addEventListener("resize", windowResize);

    windowResize();

    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  useEffect(() => {
    if (!socket) {
      console.error("Socket not initialized");
      return;
    }
    socket.on("messageRecieved", async (msg) => {
      msg = msg?.data;
      trackEvent("message-received");
      // console.log("Received message from socket:", msg);
      if (msg?.role && (msg?.role === "user" || msg?.role === "guest")) {
        console.log("msg?.role", msg);
        console.log("msgdata", JSON.stringify(msg, null, 2));

        console.log("msgtype", typeof msg);

        toast.success(
          `New Message from ${
            msg?.conversation.user.firstName ||
            msg?.conversation.user.first_name
          }`
        );
      }
      // console.log("mssg", msg);
      // find converstaion in array from conv id
      const conversation = msg?.conversation;
      conversation.lastMessage = msg;
      conversation.hotel = msg?.hotel;
      // delete msg.conversation;
      // console.log("cons", conversations);
      // console.log("con", conversation);

      if (conversation && conversation.lastMessage) {
        conversation.lastMessage.message = msg?.message;
      } else {
        const fetchedConversation = await getConversationByIdApi(
          // conversation?._id
          msg.conversationId
        );
        // console.log("fe", fetchedConversation);

        if (fetchedConversation) {
          setConversations((prevConversations) => [
            fetchedConversation.data,
            ...prevConversations,
          ]);
          if (!fetchedConversation.lastMessage) {
            fetchedConversation.lastMessage = {};
          }
          fetchedConversation.lastMessage.message = msg?.message;
        }
      }

      const filteredConversations = conversations.filter(
        (conv) => conv?._id !== msg.conversationId
      );

      filteredConversations.unshift(conversation);
      setConversations(filteredConversations);

      // console.log("fil", filteredConversations);
      if (selectedChat && selectedChat._id === conversation._id) {
        appendChat(msg);
      }
      const audioElement = new Audio(notificationSound);

      // const audioElement = new Audio(
      //   "https://cdn.jsdelivr.net/npm/whatsapp-notification-sound@1.0.0/notification.mp3"
      // );

      if (msg?.role === "user") {
        audioElement
          .play()
          .catch((error) =>
            console.error("Failed to play notification sound:", error)
          );
      } else {
        console.log(
          "Notification sound will not play. Message role is not 'user'."
        );
      }
    });

    return () => {
      console.log("Cleaning up socket listener for messageRecieved");
      socket.off("messageRecieved");
    };
  }, [conversations, selectedChat, socket]);

  const appendChat = (msg) => {
    // console.log("append msg before", messages.length, messages);
    // console.log("append msg obj", msg);
    // if (selectedChat && selectedChat.conversationId === msg.conversationId) {
    const newMessageObj = {
      message: msg.message,
      role: msg.role,
      messageTime: dayjs(msg?.timestamp).format("hh:mm A"),
    };
    // console.log("time", newMessageObj);

    var appendedMessages = [newMessageObj, ...messages];
    setMessages(appendedMessages);
    // }
    // console.log("append msg hahaha", messages.length, appendedMessages);
  };

  const handleFilterChange = async (newFilter) => {
    trackEvent("changed-filter", newFilter);
    try {
      console.log("heelo", newFilter);
      setButtonLoading(true);
      setFilter(newFilter);
      setHasMore(true);
      await fetchConversations({ isReset: true, newFilter });
      console.log("calling api???", newFilter);
    } finally {
      setButtonLoading(false);
    }
    // fetchFilteredConversations(newFilter);
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Layout content="stretch" title="Message | Chats">
          <div className={parentClasses} id="chatWrap">
            <div className="nk-chat-aside">
              <SimpleBar className="nk-chat-aside-scroll">
                <div
                  style={{
                    display: "flex",
                    padding: "5px 20px",
                    position: "sticky",
                    top: "0px",
                    background: "white",
                    zIndex: "99",
                  }}
                >
                  <button
                    onClick={() => handleFilterChange("all")}
                    style={{
                      background:
                        filter === "all"
                          ? "var(--Light-blue, #EAF7FF)"
                          : "#F2F2F2",
                      color:
                        filter === "all"
                          ? "var(--Blue, #1764D1)"
                          : "var(--Grey, #494545)",
                      border: "none",
                      padding: "5px",
                      height: "30px",
                      width: "80px",
                      borderRadius: "50px",
                      marginRight: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    All
                  </button>
                  <button
                    onClick={() => handleFilterChange("unread")}
                    style={{
                      background:
                        filter === "unread"
                          ? "var(--Light-blue, #EAF7FF)"
                          : "#F2F2F2",
                      color:
                        filter === "unread"
                          ? "var(--Blue, #1764D1)"
                          : "var(--Grey, #494545)",
                      border: "none",
                      padding: "5px",
                      height: "30px",
                      width: "80px",
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Unread
                  </button>
                </div>
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {buttonLoading ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        {/* <Loader /> */}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <ChatList>
                          {conversations.length > 0 ? (
                            conversations.map((item, index) => {
                              return (
                                <ChatList.Item
                                  key={item._id}
                                  onClick={() =>
                                    handleChatListToggle(item, index)
                                  }
                                  className={active === item?._id ? "active" : ""}
                                >
                                  <MediaGroup>
                                    <Media
                                      shape="circle"
                                      middle
                                      style={{
                                        background: "#D4C7EE",
                                        width: "60px",
                                        height: "60px",
                                      }}
                                    >
                                      <div className="w-4 h-4 rounded-full " />
                                      {item?.channel === "web" && (
                                        <TbWorldSearch
                                          size={30}
                                          style={{ color: "#aa90de" }}
                                        />
                                      )}
                                      {item?.channel === "whatsapp" && (
                                        <SiWhatsapp
                                          size={30}
                                          style={{ color: "#aa90de" }}
                                        />
                                      )}
                                    </Media>
                                    <MediaText>
                                      <span
                                        className="title"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "15px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "normal",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item?.hotel?.name?.split(" ")[0] || item?.chain?.name?.split(" ")[0]}
                                          </span>
                                          <br />
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {
                                              item?.hotel?.name.substring(
                                              item?.hotel?.name.indexOf(" ") + 1) || 
                                              item?.chain?.name
                                            }
                                          </span>
                                        </span>

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              left: "65px",
                                              marginTop: "60px",
                                              background: "white",
                                              borderRadius: "50%",
                                              height: "25px",
                                              width: "25px",
                                              border: "1px solid gray",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {item?.isBotActive ? (
                                              <Icon
                                                name="reddit"
                                                size={100}
                                                style={{
                                                  color: "#478ffc",
                                                }}
                                              />
                                            ) : (
                                              <Icon
                                                name="user"
                                                size={100}
                                                style={{ color: "#478ffc" }}
                                              />
                                            )}
                                          </span>

                                          <span
                                            className="date"
                                            style={{
                                              color: "#000",
                                              fontFamily: "Inter",
                                              fontSize: "12px",
                                              fontStyle: "normal",
                                              fontWeight: "500",
                                              lineHeight: "normal",
                                            }}
                                          >
                                            {(() => {
                                              const createdAtDate = new Date(
                                                item.lastMessage.timestamp
                                              );
                                              const today = new Date();

                                              const isToday =
                                                createdAtDate.toDateString() ===
                                                today.toDateString();

                                              if (isToday) {
                                                return createdAtDate.toLocaleTimeString(
                                                  [],
                                                  {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  }
                                                );
                                              } else {
                                                // return createdAtDate.toLocaleDateString(
                                                //   "en-US",
                                                //   {
                                                //     day: "2-digit",
                                                //     month: "short",
                                                //     year: "2-digit",
                                                //   }
                                                // );

                                                const day = createdAtDate
                                                  .getDate()
                                                  .toString()
                                                  .padStart(2, "0");
                                                const month =
                                                  createdAtDate.toLocaleString(
                                                    "en-US",
                                                    {
                                                      month: "short",
                                                    }
                                                  );
                                                const year =
                                                  createdAtDate.toLocaleString(
                                                    "en-US",
                                                    { year: "2-digit" }
                                                  );

                                                return `${day}, ${month} ${year}`;
                                              }
                                            })()}
                                          </span>
                                        </div>
                                      </span>

                                      <div
                                        className=" smaller"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          color: "#787c9e",
                                        }}
                                      >
                                        <span>
                                          {item?.lastMessage?.message?.slice(
                                            0,
                                            20
                                          )}
                                        </span>

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          {!item.isRead && (
                                            <span
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                backgroundColor: "green",
                                              }}
                                            ></span>
                                          )}
                                        </div>
                                      </div>
                                    </MediaText>
                                  </MediaGroup>
                                  <div
                                    ref={
                                      index === conversations.length - 1
                                        ? lastChatRef
                                        : null
                                    }
                                  />
                                </ChatList.Item>
                              );
                            })
                          ) : (
                            <div
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              {filter === "unread" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "60vh",
                                  }}
                                >
                                  <p style={{ color: "gray" }}>
                                    No unread messages
                                  </p>
                                  <p
                                    style={{
                                      color: "var(--Blue, #1764D1)",
                                      cursor: "pointer  ",
                                    }}
                                    onClick={() => handleFilterChange("all")}
                                  >
                                    View all chats
                                  </p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "60vh",
                                  }}
                                >
                                  <p>No messages yet</p>
                                </div>
                              )}
                            </div>
                          )}
                        </ChatList>{" "}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="pagination-controls"
                  // ref={lastChatRef}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // background: "pink",
                    // height: "80vh",
                  }}
                >
                  {isScrollLoading && hasMore && <Loader size="sm" />}
                  {/* {!loading ? (
                    <>
                      <Button
                        onClick={previousPage}
                        disabled={currentPage === 1}
                        size="md"
                        variant="zoom"
                        className="btn-icon toggle-close-chat"
                      >
                        <Icon name="caret-left-fill" />
                      </Button>
                      <Button
                        onClick={nextPage}
                        disabled={nextDisabled}
                        size="md"
                        variant="zoom"
                        className="btn-icon toggle-close-chat"
                      >
                        <Icon name="caret-right-fill" />
                      </Button>
                    </>
                  ) : null} */}
                </div>
                <div
                  // ref={lastChatRef}
                  style={{
                    border: "5px solid transparent",
                    textAlign: "center",
                  }}
                >
                  {/* {isScrollLoading
                ? "Loading Conversations"
                : "No more conversations"} */}
                </div>
              </SimpleBar>
            </div>

            <div className={chatBodyClasses} id="chatBody">
              <div className={chatBodyClasses} id="chatBody">
                {selectedChat ? (
                  <ChatMessageScreen
                    loadingMessages={loadingMessages}
                    selectedChat={selectedChat}
                    messages={messages}
                    setMessages={setMessages}
                    onBack={handleChatListHide}
                    selectedHotel={selectedHotel}
                    setIsAI={setIsAI}
                    isAI={isAI}
                    randomLastName={randomLastName}
                  />
                ) : (
                  <div
                    className="nk-chat-blank"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <div
                      className="nk-chat-blank-icon"
                      style={{ fontSize: "30px" }}
                    >
                      <Icon name="chat" />
                    </div>
                    <div
                      className="nk-chat-blank-text"
                      style={{ textAlign: "center" }}
                    >
                      <h3 className="title">No Chat Selected</h3>
                      <p>Select a chat to start messaging</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
}

export default ChatsPage;
